import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

Vue.config.productionTip = false

function generateRandomString(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }
  return result;
}

function initEquipment() {
  const equipment = localStorage.getItem('equipment');
  if (equipment) {
    window.equipment = equipment
    console.log('本设备设备号：' + equipment)
  }else {
    const timestamp = Date.now().toString();
    const randomString = generateRandomString(10);
    const randomNumber = timestamp + randomString;
    console.log('本设备设备号：' + randomNumber)
    localStorage.setItem('equipment', randomNumber);
    window.equipment = randomNumber
  }
}

initEquipment()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
